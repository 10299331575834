<template>
  <figure ref="imageRef" class="image-container">
    <BaseImage
      :image="image"
      :width="image.width ?? undefined"
      :height="image.height ?? undefined"
      :img-sizes="[
        { w: 414, h: 233, resize: 'fit' },
        { w: 768, h: 432, resize: 'fit' },
        { w: 1280, h: 720, resize: 'fit' },
        { w: 1920, h: 1080, resize: 'fit' }
      ]"
      sizes="100vw"
      :usemap="`#imagemap_${id}`"
      fit="fit"
    />
    <map :name="`imagemap_${id}`">
      <area
        v-for="area in areas"
        :key="area.id"
        :title="area.title"
        :alt="area.title"
        shape="rect"
        :coords="area.coords"
        :href="area.url || undefined"
        :target="area.url ? '_blank' : undefined"
        class="area"
        :role="!area.url ? 'heading' : undefined"
        :aria-level="!area.url ? '2' : undefined"
        :aria-label="
          area.url
            ? `${area.title} - ${t('a11y.opening-in-new-tab')}`
            : area.title
        "
      />
    </map>
  </figure>
</template>

<script lang="ts" setup>
import { debounce } from 'lodash-es'

import { PublicImage, PublicMapArea } from '@/service/__generated-api'

const { t } = useI18n()

const props = defineProps<{
  id: string
  image: PublicImage
  mapAreas: PublicMapArea[]
}>()

const imageRef = ref<HTMLElement>()

type Area = {
  id: string
  title?: string
  url?: string
  coords: string
}
const areas = ref<Area[]>([])
const errorMargin = 50

const setAreas = debounce(() => {
  if (!imageRef.value) {
    return
  }

  areas.value = [...props.mapAreas]
    .sort((a, b) => {
      // First compare by y
      if (a.y !== b.y) {
        if (Math.abs(a.y - b.y) <= errorMargin) {
          // If y values are within the error margin, compare by x
          return a.x - b.x
        }
        // Otherwise, sort by y normally
        return a.y - b.y
      }

      // If y values are equal, compare by x
      return a.x - b.x
    })
    .map(area => {
      const renderedWidth = imageRef.value!.offsetWidth
      const renderedHeight = imageRef.value!.offsetHeight

      const widthScale = renderedWidth / (props.image.width ?? 1)
      const heightScale = renderedHeight / (props.image.height ?? 1)

      const startX = area.x * widthScale
      const startY = area.y * heightScale
      const endX = (area.x + area.width) * widthScale
      const endY = (area.y + area.height) * heightScale
      return {
        id: area.id,
        title: area.title,
        url: area.url,
        coords: `${startX},${startY},${endX},${endY}`
      }
    })
}, 100)

watch(() => props, setAreas, { immediate: true })

onMounted(() => {
  window.addEventListener('resize', setAreas)

  nextTick(() => {
    setAreas()
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setAreas)
})
</script>

<style lang="scss" scoped>
.image-container {
  grid-row: 1;
  grid-column: 3 / 7;

  @include media-up(lg) {
    grid-column: 4 / 25;
  }
}

.area {
  @include focus-visible;
}
</style>
